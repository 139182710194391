
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "@1024pix/ember-matomo-tag-manager/-embroider-implicit-modules.js";
import "@ember-data/json-api/-embroider-implicit-modules.js";
import "@ember-data/model/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-cli-mirage/-embroider-implicit-modules.js";
import "ember-cookies/-embroider-implicit-modules.js";
import "ember-data/-embroider-implicit-modules.js";
import "ember-dayjs/-embroider-implicit-modules.js";
import "ember-keyboard/-embroider-implicit-modules.js";
import "ember-lifeline/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-page-title/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "ember-simple-auth/-embroider-implicit-modules.js";
import "ember-truth-helpers/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "@1024pix/ember-api-actions/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "@1024pix/ember-testing-library/-embroider-implicit-modules.js";
import "ember-cli-sass/-embroider-implicit-modules.js";
import "@1024pix/pix-ui/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "@ember/string/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "@embroider/util/-embroider-implicit-modules.js";
import "@fortawesome/ember-fontawesome/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "@sentry/ember/-embroider-implicit-modules.js";
import "ember-cli-autoprefixer/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-cli-clipboard/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-cli-deprecation-workflow/-embroider-implicit-modules.js";
import "ember-component-css/-embroider-implicit-modules.js";
import "ember-exam/-embroider-implicit-modules.js";
import "ember-fetch/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
import "ember-responsive/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
